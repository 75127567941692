var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"list",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"iconTable",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.arrayRules.data,"disable-pagination":"","hide-default-footer":true,"loading":_vm.fetchingData,"loading-text":'Cargando... Espere por favor',"no-data-text":'datos no disponibles',"item-key":"_id","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header",staticStyle:{"background-color":"#299F7E"}},[_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:header.value,class:header.align},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(header.text)+" ")])])],1)],1)]})],2)])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('td',{style:(_vm.arrayRules.data.indexOf(item) % 2 != 0
            ? 'background:#75D89F1A!important'
            : '')},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.score",fn:function(ref){
            var item = ref.item;
return [_c('td',{style:(_vm.arrayRules.data.indexOf(item) % 2 != 0
            ? 'background:#75D89F1A!important'
            : '')},[_vm._v(" "+_vm._s(item.score)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('td',{style:(_vm.arrayRules.data.indexOf(item) % 2 != 0
            ? 'background:#75D89F1A!important'
            : '')},[_c('v-btn',{attrs:{"icon":"","color":"primary","to":'/puntaje/edit/' + item.id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"12"}},[_c('v-pagination',{attrs:{"length":_vm.arrayRules.last_page,"total-visible":"7","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left","circle":""},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }